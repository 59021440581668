import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../common/colors';
import DigTooltip from '@mdigital/components/dist/components/Tooltip';
import DigButton from '@mdigital/components/dist/components/Button';
import { getSingleText } from '../../../common/utils';

export default function RefreshButton({
  refreshConfig,
}) {
  const refreshFormTexts = getSingleText('app.pages.forms.refreshForm');
  const counter = refreshConfig.isWebsiteProperty && refreshConfig.selectedCount && !refreshConfig.isPollingFormsRefresh ? `(${refreshConfig.selectedCount} ${refreshFormTexts.selected})` : '';
  const tooltip = refreshFormTexts.button.tooltip + (refreshConfig.isWebsiteProperty ? refreshFormTexts.button.web_tooltip_addon : '');

  return (
    <React.Fragment>
      <i id="separator"/>
      <DigTooltip name="refresh" digTooltip={tooltip} digElement={() => <DigButton
        digClassName="action-form-button"
        digAutLabel="refreshForm"
        digOnClick={() => refreshConfig.setShowRefreshConfirmationModal(true)}
        digType="link"
        digIconClassName="refresh"
        digIconColor={null}
        digTextColor={COLORS.BLUE}
        digTextColorHover={COLORS.TEXT_HOVER}
        isLoading={refreshConfig.isPollingFormsRefresh}
        digIsDisabled={refreshConfig.isDisableRefreshForm}>{`${refreshFormTexts.button.text} ${counter}`}
      </DigButton>}/>
    </React.Fragment>
  );
}

RefreshButton.propTypes = {
  refreshConfig: PropTypes.shape({
    isDisableRefreshForm: PropTypes.bool,
    isPollingFormsRefresh: PropTypes.bool,
    areAllItemsChecked: PropTypes.bool,
    selectedCount: PropTypes.number,
    setShowRefreshConfirmationModal: PropTypes.func,
    isWebsiteProperty: PropTypes.bool,
  }),
};
